.policy-content1 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #F7F7F2;
  padding: 50px 0;

  @media (max-width: 1023px) {
    padding: 0;
  }

  &.dark {
    background-color: #FFF;
    &:before {
      background-color: #EBE5D3;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 45vw;
    border-right: 1px solid $dark;
    background-color: #F7F7F2;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .content-container {
    position: relative;
    @extend .width-container;
    align-items: center;

    @media (max-width: 1023px) {
      flex-direction: column;
      padding: 0;
    }

    .left-side {
      width: calc(45vw - ((100vw - 100%) / 2));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 50px;

      @media (max-width: 1023px) {
        display: none;
      }

      .content-heading {
        color: var(--primary-black, #131313);
        font-family: $fontSimula;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 113%;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .right-side {
      width: calc(55vw - ((100vw - 100%) / 2));
      padding-left: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 30px;
      }

      .content-subheading {
        color: var(--primary-black, #131313);
        font-family:$fontSuisse;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
      }

      .content-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        * {
          margin: 0;
        }

        h4:not(:first-child) {
          margin-top: 30px;
        }
      }
    }
  }
}

.policy-content2 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #FFF;
  padding: 50px 0;

  @media (max-width: 1023px) {
    padding: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 45vw;
    border-right: 1px solid $dark;
    background-color: #F7F7F2;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .content-container {
    position: relative;
    @extend .width-container;
    align-items: flex-start;

    @media (max-width: 1023px) {
      flex-direction: column;
      padding: 0;
    }

    .left-side {
      position: relative;
      width: calc(45vw - ((100vw - 100%) / 2));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 50px;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 30px;
        background-color: #EBE5D3;
      }

      &:before {
        content: '';
        position: absolute;
        top: -50px;
        bottom: -50px;
        right: 0;
        width: 45vw;
        background-color: #EBE5D3;

        @media (max-width: 1023px) {
          display: none;
        }
      }

      .content-heading {
        position: relative;
        color: var(--primary-black, #131313);
        font-family: $fontSimula;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 113%;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .right-side {
      width: calc(55vw - ((100vw - 100%) / 2));
      padding-left: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 30px;
      }

      .content-subheading {
        color: var(--primary-black, #131313);
        font-family:$fontSuisse;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
      }

      .content-text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        * {
          margin: 0;
        }

        h4:not(:first-child) {
          margin-top: 30px;
        }
      }
    }
  }
}
