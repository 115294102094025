.home-split {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;

    &.mobile-reversed {
      flex-direction: column-reverse;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 45vw;
    width: 1px;
    background-color: $dark;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &.beige {
    background-color: $beige;
  }

  &.lightGold {
    background-color: $lightGold;
  }

  &.with-round {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NTkgNjU5Ij48Y2lyY2xlIG9wYWNpdHk9Ii42IiBjeD0iMzI5LjUiIGN5PSIzMjkuNSIgcj0iMzI5IiBzdHJva2U9IiMxMzEzMTMiLz48L3N2Zz4=");
    background-repeat: no-repeat;
    background-size: auto calc(100% - 160px);
    background-position: right 25% center;

    @media (max-width: 767px) {
      background-image: none;
    }
  }

  &.reversed {
    &.with-round {
      background-position: right 65% center;
    }

    .image-container {
      left: initial;
      right: 0;
      width: calc(55vw - 1px);

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .content-container {
      .content-side-container {
        width: calc(45vw - ((100vw - 100%) / 2));
        padding-left: 0;
        padding-right: 80px;

        @media (max-width: 1023px) {
          padding-right: 40px;
        }

        @media (max-width: 767px) {
          width: 100%;
          padding: 50px 0;
        }
      }
    }
  }

  .image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 45vw;
    display: flex;

    @media (max-width: 767px) {
      position: relative;
      width: 100%;
      height: 420px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .content-container {
    @extend .width-container;

    .content-side-container {
      padding-top: 120px;
      padding-bottom: 120px;
      padding-left: calc(45vw - ((100vw - 100%) / 2) + 100px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &.home {
        padding-top: 300px;
        padding-bottom: 300px;

        @media (max-width: 1023px) {
          padding-top: 75px;
          padding-bottom: 75px;
        }
      }

      @media (max-width: 1023px) {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: calc(45vw - ((100vw - 100%) / 2) + 40px);
      }

      @media (max-width: 767px) {
        padding: 50px 0;
      }

      h2, h3, p {
        margin: 0 0 30px;
      }

      .split-link {
        @extend .inline-link;
        margin-top: 20px;
      }

      .content-slider {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;

        @media (max-width: 767px) {
          margin-top: 0;
        }

        .slider-pagination {
          width: 100%;
          display: flex;
          align-items: center;
          margin: 0 0 30px;
          @extend h2;
          color: $blue;
          gap: 10px;

          span:last-child {
            opacity: 0.6;
          }
        }

        .slider-container {
          width: 100%;
          padding-bottom: 90px;

          @media (max-width: 767px) {
            padding-bottom: 70px;
          }

          .flickity-viewport {
            width: 100%;
          }

          .flickity-prev-next-button {
            left: 0;
            right: initial;
            top: initial;
            bottom: 0;
            transform: none;
            width: 40px;
            height: 40px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PGNpcmNsZSBjeD0iMjAiIGN5PSIyMCIgcj0iMTkuNSIgc3Ryb2tlPSIjRDhDQUE1Ii8+PHBhdGggZD0iTTI2LjEyNSAyMC42MzFIOS44NnYtLjgxOGgxNi4yNjVjLTIuMjYtMS4xOC0zLjU2OC0zLjYyNC0zLjg5Ni03LjI5bC43OTUtLjA3OWMuNDQyIDQuOTQ1IDIuNjkgNy4zNDYgNi44MzYgNy4zNDZ2Ljg2NGMtNC4xNDUgMC02LjM5NCAyLjQwMS02LjgzNiA3LjM0NmwtLjc5NS0uMDhjLjMyOC0zLjY2NSAxLjYzNi02LjExIDMuODk2LTcuMjg5WiIgZmlsbD0iIzEzMTMxMyIvPjwvc3ZnPg==");
            background-color: transparent;

            &.next {
              left: 50px;
            }

            &.previous {
              transform: rotate(180deg);
            }

            svg {
              display: none;
            }
          }

          .slider-item {
            width: 100%;
            display: flex;

            .item-text {
              @extend .p2;
              margin: 0;
              color: $lightBlack;
            }
          }
        }
      }

      .services-list {
        width: 100%;
        max-width: 385px;
        display: flex;
        flex-direction: column;
        gap: 90px;

        @media (max-width: 767px) {
          gap: 50px;
        }

        .list-item {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-left: 30px;

          @media (max-width: 767px) {
            padding-left: 0;
          }

          &:before {
            content: "";
            position: absolute;
            top: -30px;
            left: 0;
            width: 110px;
            height: 110px;
            border-radius: 110px;
            background-color: $white;

            @media (max-width: 767px) {
              top: -20px;
              left: -10px;
              width: 90px;
              height: 90px;
              border-radius: 90px;
            }
          }

          .item-heading {
            position: relative;
            margin: 0 0 10px;
          }

          .item-text {
            position: relative;
            margin: 0;
            @extend .p1;
            color: $lightBlack;
          }
        }
      }
    }
  }
}
