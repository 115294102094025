.big-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: $beige;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: all .7s ease-in-out;
  will-change: transform, opacity;
  display: flex;
  transform: translateX(20%);
  opacity: 0;
  pointer-events: none;

  @media (max-width: 767px) {
    transition-duration: .5s;
  }

  &.is-open {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;

    .big-menu-container {
      .false-left-site {
        width: 45vw;

        @media (max-width: 767px) {
          width: 35vw;
        }
      }

      .false-right-site {
        width: 200px;
      }

      .center-line {
        position: relative;

        &:before {
          width: 100%;
        }

        .circle-wrapper {
          position: absolute;
          top: -60px;
          right: calc(50vw - 80px);
          width: 550px;

          @media (max-width: 1023px) {
            left: 40px;
            right: initial;
          }

          @media (max-width: 767px) {
            left: 50%;
            transform: translateX(-50%);
          }

          .circle-container {
            width: 100%;
            padding-top: 100%;

            .roadmap-circle {
              position: absolute;
              inset: 0;
              width: 100%;
              height: 100%;

              .circle-foreground {
                r: calc(550px / 2);
                cx: 50%;
                cy: 50%;
                fill: none;
                stroke-width: 1px;
                stroke: rgba($dark, 0.6);
                stroke-dasharray: calc(550px * 3.14);
                stroke-dashoffset: calc(550px * 3.14);
                transform-origin: 50% 50%;
                transform: rotate(-90deg);
                animation-name: hero-circle-draw;
                animation-duration: 1s;
                animation-delay: 1s;
                animation-fill-mode: forwards;

                @media (max-width: 767px) {
                  r: calc(335px / 2);
                  stroke-dasharray: calc(335px * 3.14);
                  stroke-dashoffset: calc(335px * 3.14);
                  animation-name: hero-circle-draw-mobile;
                }

                @keyframes hero-circle-draw {
                  from {
                    stroke-dashoffset: calc(550px * 3.14);
                  }
                  to {
                    stroke-dashoffset: 0;
                  }
                }

                @keyframes hero-circle-draw-mobile {
                  from {
                    stroke-dashoffset: calc(335px * 3.14);
                  }
                  to {
                    stroke-dashoffset: 0;
                  }
                }
              }
            }
          }
        }

        .links-container .menu-link {
          transform: none;
          opacity: 1;

          @media (max-width: 767px) {
            &:first-child:before {
              width: calc(100% + 60px);
            }
          }
        }
      }

      .false-left-site:before {
        height: 100%;
      }

      .bottom-line {
        &:before {
          width: 100%;
        }

        .language-link {
          transform: none;
          opacity: 1;
        }
      }
    }
  }

  .big-menu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .false-left-site {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 55vw;
      background-color: $white;
      z-index: 12;
      transition: width 1s ease-in-out;
      transition-delay: .2s;

      @media (max-width: 1023px) {
        width: calc(100vw - 200px);
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 0;
        background-color: $dark;
        transition: height 0.6s ease-in-out;
        transition-delay: .7s;

        @media (max-width: 767px) {
          opacity: .6;
        }
      }
    }

    .false-right-site {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 0;
      background-color: $lightGold;
      z-index: 11;
      transition: width 0.6s ease-in-out;
      transition-delay: .5s;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .close-trigger {
      position: absolute;
      z-index: 13;
      top: 40px;
      right: 40px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: 767px) {
        top: 0;
        right: 10px;
      }

      svg {
        height: 30px;
      }
    }

    .top-line {
      position: relative;
      z-index: 12;
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 40px 0;

      @media (max-width: 767px) {
        padding: 10px 0;
        background-color: $lightGold;
        flex: 0;
      }

      .logo-container {
        @extend .width-container;

        .header-logo {
          display: flex;

          svg {
            height: 70px;

            @media (max-width: 767px) {
              height: 50px;
            }
          }
        }
      }
    }

    .center-line {
      position: relative;
      z-index: 12;
      display: flex;
      justify-content: center;
      padding: 50px 0 70px;

      @media (max-width: 767px) {
        flex: 1;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $dark;
        transition: width 0.6s ease-in-out;
        transition-delay: .9s;
        opacity: .6;

        @media (max-width: 767px) {
          display: none;
        }
      }

      .links-container {
        flex: 1;
        width: 45%;
        @extend .width-container;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 70px;

        @media (max-width: 767px) {
          width: 100%;
          gap: 60px;
        }

        .menu-link {
          position: relative;
          z-index: 13;
          @extend h1;
          @extend .small;
          margin: 0;
          width: calc(45vw - ((100vw - 100%) / 2));
          transform: translateX(200px);
          opacity: 0;
          transition: transform 0.6s ease-in-out, opacity 0.8s ease-in-out;

          @for $i from 1 to 6 {
            &:nth-child(#{$i}) {
              transition-delay: .4s + $i * 0.1s;

              @media (max-width: 767px) {
                transition-delay: .3s + $i * 0.1s;
              }
            }
          }

          &:hover {
            &:before {
              width: 45vw;
            }

            &:after {
              transform: translate(50%, -50%);
              opacity: 1;
            }
          }

          @media (min-width: 1024px) {
            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 2px;
              left: calc(100% - 45vw);
              top: 120%;
              transform: translateY(-50%);
              background-color: $dark;
              transition: width 0.3s ease-in-out;
            }

            &:after {
              content: '';
              position: absolute;
              right: 0;
              top: 120%;
              width: 80px;
              height: 80px;
              opacity: 0;
              transform: translate(-100%, -50%);
              background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5MCA5MCI+PGNpcmNsZSBjeD0iNDUiIGN5PSI0NSIgcj0iNDUiIGZpbGw9IiNEOENBQTUiLz48cGF0aCBkPSJNNTguNzggNDYuNDIxSDIyLjE4NlY0NC41OGgzNi41OTZjLTUuMDg0LTIuNjUzLTguMDI4LTguMTU0LTguNzY3LTE2LjRsMS43OS0uMTc5Yy45OTUgMTEuMTI1IDYuMDU0IDE2LjUyOSAxNS4zODIgMTYuNTI5djEuOTQyYy05LjMyOCAwLTE0LjM4NyA1LjQwNC0xNS4zODIgMTYuNTI5bC0xLjc5LS4xNzhjLjczOS04LjI0NyAzLjY4My0xMy43NDggOC43NjctMTYuNFoiIGZpbGw9IiMxMzEzMTMiLz48L3N2Zz4=");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center center;
              transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
              transition-delay: .1s;

              @media (max-height: 850px) {
                width: 50px;
                height: 50px;
              }
            }
          }

          @media screen and (min-width: 768px) and (max-height: 850px) {
            font-size: 36px;
          }

          @media screen and (min-width: 768px) and  (max-height: 700px) {
            font-size: 28px;
          }

          @media (max-width: 767px) {
            width: 100%;
            font-size: 44px;
            transform: translateX(100px);

            &:first-child:before {
              content: '';
              position: absolute;
              top: -40px;
              left: -30px;
              width: 0;
              height: 1px;
              background-color: $dark;
              transform: width 0.3s ease-in-out;
              transition-delay: .5s;
            }
          }
        }
      }
    }

    .bottom-line {
      position: relative;
      z-index: 12;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 20px 60px 20px 0;

      @media (max-width: 767px) {
        padding: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $dark;
        transition: width 0.6s ease-in-out;
        transition-delay: .7s;
        opacity: .6;

        @media (max-width: 767px) {
          display: none;
        }
      }

      .language-link {
        @extend h3;
        transform: translateX(100px);
        opacity: 0;
        transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
        transition-delay: .5s;

        @media (min-width: 768px) {
          position: absolute;
          top: calc(-100vh + 95px);
          right: 250px;
        }
      }
    }
  }
}
