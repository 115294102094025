.home-history {
  width: 100%;
  display: flex;
  flex-direction: column;

  .heading-line {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $blue;
    padding: 90px 0 80px;

    @media (max-width: 1023px) {
      padding: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 45vw;
      background-color: $lightBlue;
      border-right: 1px solid $dark;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    .heading-container {
      position: relative;
      @extend .width-container;
      align-items: center;

      @media (max-width: 1023px) {
        flex-direction: column;
        padding: 0;
      }

      .left-side {
        width: calc(45vw - ((100vw - 100%) / 2));

        @media (max-width: 1023px) {
          width: 100%;
          padding: 50px 30px;
          background-color: $lightBlue;
        }

        .history-heading {
          margin: 0;
        }
      }

      .right-side {
        width: calc(55vw - ((100vw - 100%) / 2));
        padding-left: 100px;

        @media (max-width: 1023px) {
          width: 100%;
          padding: 50px 30px;
        }

        .history-text {
          margin: 0;
        }
      }
    }
  }

  .slider-line {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $beige;
    padding: 200px 0;
    overflow: hidden;

    @media (max-width: 1023px) {
      padding: 100px 0;
    }

    @media (max-width: 767px) {
      background-color: $white;
      padding: 80px 0 120px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 45vw;
      background-color: $white;
      border-right: 1px solid $dark;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .slider-container {
      position: relative;
      @extend .width-container;

      .slider-round-container {
        position: absolute;
        width: 53%;
        left: calc((45vw - ((100vw - 100%) / 2)) - (350px / 2));
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 3;
        pointer-events: none;

        @media (max-width: 1023px) {
          width: 80%;
          left: calc((45vw - ((100vw - 100%) / 2)) - (450px / 2));
        }

        @media (max-width: 767px) {
          width: 540px;
          left: calc((45vw - ((100vw - 100%) / 2)) - (250px / 2));
        }

        .slider-round {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          width: 100%;
          padding-top: 100%;
          border-radius: 100%;
          border: 1px solid $dark;

          @media (max-width: 767px) {
            border-color: rgba($dark, 0.25);
          }

          .slider-btn {
            position: absolute;
            top: 50%;
            width: 90px;
            height: 90px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iOTAiIHZpZXdCb3g9IjAgMCA5MCA5MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDUiIGN5PSI0NSIgcj0iNDUiIGZpbGw9IiNEOENBQTUiLz4KPHBhdGggZD0iTTU5LjQ0MjkgNDYuMjE4NkgyMi4xODU1TDIyLjE4NTUgNDQuMzk2OEg1OS40NDI5QzU0LjI2NzcgNDEuNzczMyA1MS4yNjk5IDM2LjMzMjYgNTAuNTE4MiAyOC4xNzYxTDUyLjMzOTkgMjhDNTMuMzUzMiAzOS4wMDI5IDU4LjUwMzggNDQuMzQ3IDY4IDQ0LjM0N1Y0Ni4yNjg0QzU4LjUwMzggNDYuMjY4NCA1My4zNTMyIDUxLjYxMjUgNTIuMzM5OSA2Mi42MTU0TDUwLjUxODIgNjIuNDM5M0M1MS4yNjk5IDU0LjI4MjggNTQuMjY3MSA0OC44NDIxIDU5LjQ0MjkgNDYuMjE4NloiIGZpbGw9IiMxMzEzMTMiLz4KPC9zdmc+Cg==");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            pointer-events: auto;
            cursor: pointer;
            transition: opacity ease-in-out 0.2s;

            @media (max-width: 767px) {
              width: 60px;
              height: 60px;
            }

            &.next {
              right: 0;
              transform: translate(50%,-50px);
            }

            &.previous {
              left: 0;
              transform: translate(-50%,-50px) rotate(180deg);

              @media (max-width: 1023px) {
                display: none;
              }
            }

            &.is-hidden {
              opacity: 0;
            }
          }
        }
      }

      .slider-wrapper {
        position: relative;
        width: 100%;
        padding-left: calc(45vw - ((100vw - 100%) / 2) - 350px + 40px);

        @media (max-width: 1023px) {
          padding-left: calc(45vw - ((100vw - 100%) / 2) - 250px + 40px);
        }

        @media (max-width: 767px) {
          padding-left: 0;
        }

        .flickity-viewport {
          width: 100%;
          overflow: visible;
        }

        .slider-item {
          width: 350px;
          margin-right: 60px;
          transition: all ease-in-out 0.2s;
          opacity: 0.2;

          @media (max-width: 1023px) {
            width: 250px;
          }

          @media (max-width: 767px) {
            width: 210px;
            margin-right: 65px;
          }

          &.is-selected {
            transform: translateX(-70px);
            opacity: 1;

            @media (max-width: 767px) {
              transform: none;
            }
          }

          &.is-hidden {
            opacity: 0;
          }

          .slider-content {
            width: 100%;
            max-width: 350px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .slider-year {
              margin: 0 0 10px;
            }

            .slider-heading {
              margin: 0 0 30px;
            }

            .slider-text {
              @extend .p4;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
