// --------------------------------------------------
// Variables
// --------------------------------------------------

// Import Web Fonts
// @import url(...);

// Fonts
$fontSimula: 'Simula', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontSuisse: 'Suisse Intl', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Colors
$dark: #131313;
$lightBlack: #686868;
$white: #FFFFFF;
$blue: #9FADBE;
$lightBlue: #DCE6EF;
$lightBrown: #DAD7CE;
$gold: #AEA697;
$lightGold: #EBE5D3;
$lightBeige: #D8CAA5;
$beige: #F7F7F2;

$colorSuccess: #00966D;
$colorError: #FF564A;
