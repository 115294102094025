.home-hero {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 220px 0 240px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (max-width: 767px) {
    padding: 180px 0 195px;
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba($white, 1);
    animation-name: hero-bg-fade;
    animation-duration: 2s;
    animation-fill-mode: forwards;

    @keyframes hero-bg-fade {
      from {background-color: rgba($white, 1);}
      to {background-color: rgba($white, 0.5);}
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 45vw;
    top: 0;
    width: 1px;
    height: 0;
    background-color: $dark;
    animation-name: hero-vertical-line;
    animation-duration: .8s;
    animation-delay: .8s;
    animation-fill-mode: forwards;

    @media (max-width: 767px) {
      background-color: rgba($dark, 0.4);
    }

    @keyframes hero-vertical-line {
      from {height: 0;}
      to {height: 100%;}
    }
  }

  .hero-container {
    position: relative;
    @extend .width-container;
    flex-direction: column;
    align-items: flex-start;

    .circle-wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 600px;
      display: flex;
      align-items: center;

      @media (max-width: 1250px) {
        left: 40px;
      }

      @media (max-width: 767px) {
        width: 335px;
      }

      @media (max-width: 520px) {
        width: 260px;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .circle-container {
        width: 100%;
        padding-top: 100%;

        .roadmap-circle {
          position: absolute;
          inset: 0;
          width: 100%;
          height: 100%;

          .circle-foreground {
            r: calc(600px / 2);
            cx: 50%;
            cy: 50%;
            fill: none;
            stroke-width: 1px;
            stroke: $dark;
            stroke-dasharray: calc(600px * 3.14);
            stroke-dashoffset: calc(600px * 3.14);
            transform-origin: 50% 50%;
            transform: rotate(-90deg);
            animation-name: hero-circle-draw;
            animation-duration: 1s;
            animation-delay: 1s;
            animation-fill-mode: forwards;

            @media (max-width: 767px) {
              r: calc(335px / 2);
              stroke-dasharray: calc(335px * 3.14);
              stroke-dashoffset: calc(335px * 3.14);
              animation-name: hero-circle-draw-mobile;
            }

            @media (max-width: 520px) {
              r: calc(260px / 2);
              stroke-dasharray: calc(260px * 3.14);
              stroke-dashoffset: calc(260px * 3.14);
              animation-name: hero-circle-draw-mini;
            }

            @keyframes hero-circle-draw {
              from {
                stroke-dashoffset: calc(600px * 3.14);
              }
              to {
                stroke-dashoffset: 0;
              }
            }

            @keyframes hero-circle-draw-mobile {
              from {
                stroke-dashoffset: calc(335px * 3.14);
              }
              to {
                stroke-dashoffset: 0;
              }
            }

            @keyframes hero-circle-draw-mini {
              from {
                stroke-dashoffset: calc(260px * 3.14);
              }
              to {
                stroke-dashoffset: 0;
              }
            }
          }
        }
      }
    }

    .home-heading {
      position: relative;
      font-family: $fontSimula;
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 1.1em;
      text-transform: uppercase;
      color: $dark;
      margin: 0 0 50px;
      transform: translateY(-40px);
      opacity: 0;
      animation-name: hero-heading-fade;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-fill-mode: forwards;

      @media (max-width: 1023px) {
        font-size: 64px;
      }

      @media (max-width: 767px) {
        font-size: 32px;
        margin-bottom: 20px;
      }

      @keyframes hero-heading-fade {
        from {
          transform: translateY(-40px);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    .home-subtitle {
      position: relative;
      transform: translateX(-40px);
      animation-name: text-block-slide;
      animation-duration: .8s;
      animation-delay: 2s;
      animation-fill-mode: forwards;

      @keyframes text-block-slide {
        from {
          transform: translateX(-40px);
        }
        to {
          transform: translateX(0);
        }
      }

      .horizontal-line {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 1px;
        width: 100vw;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 0;
          background-color: $dark;
          animation-name: hero-horizontal-line;
          animation-duration: 1s;
          animation-delay: 1.5s;
          animation-fill-mode: forwards;

          @keyframes hero-horizontal-line {
            from {width: 0;}
            to {width: 100%;}
          }
        }
      }

      span {
        font-family: $fontSuisse;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 1.3em;
        color: $dark;
        margin: 0;
        opacity: 0;
        animation-name: hero-text-fade;
        animation-duration: .8s;
        animation-delay: 2s;
        animation-fill-mode: forwards;

        @media (max-width: 767px) {
          font-size: 14px;
        }

        @keyframes hero-text-fade {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
}
