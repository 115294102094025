.services03 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $beige;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 45vw;
    width: 1px;
    background-color: $dark;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: calc(55vw - 1px);
    display: flex;

    @media (max-width: 1023px) {
      position: relative;
      width: 100%;
      height: 420px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .content-container {
    @extend .width-container;

    .content-side-container {
      width: calc(45vw - ((100vw - 100%) / 2));
      padding: 80px 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 0 0;
      }

      .content-heading {
        margin: 0 0 20px;
        padding-right: 20px;

        @media (max-width: 1023px) {
          padding: 0;
        }
      }

      .content-text {
        @extend .p1;
        margin: 0 0 70px;
        padding-right: 20px;

        @media (max-width: 1023px) {
          padding: 0;
        }
      }

      .content-list {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 120px;
          right: 0;
          background-color: $white;

          @media (max-width: 1023px) {
            left: 90px;
            right: -40px;
          }
        }

        .list-item {
          position: relative;
          width: 100%;
          display: flex;
          align-items: flex-end;

          .item-number {
            width: 120px;
            font-family: $fontSimula;
            font-style: normal;
            font-weight: 400;
            font-size: 184px;
            line-height: .8em;
            color: $blue;
            padding-bottom: 80px;
            background-color: $beige;

            @media (max-width: 767px) {
              font-size: 124px;
              width: 90px;
              padding-bottom: 50px;
            }
          }

          .item-text {
            width: calc(100% - 120px);
            padding: 0 40px 80px;
            @extend .p2;
            color: $lightBlack;

            @media (max-width: 767px) {
              width: calc(100% - 90px);
              padding: 0 0 50px 30px;
            }
          }
        }
      }
    }
  }
}
