.home-about {
  width: 100%;
  display: flex;
  flex-direction: column;

  .about-top {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 160px 0 100px;
    background-color: $lightBrown;

    @media (max-width: 1023px) {
      padding: 0;;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width:45vw;
      background-color: $gold;
      border-right: 1px solid $dark;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    .top-container {
      position: relative;
      @extend .width-container;

      @media (max-width: 1023px) {
        flex-direction: column;
        padding: 0;
      }

      .heading-container {
        width: calc(45vw - ((100vw - 100%) / 2));

        @media (max-width: 1023px) {
          width: 100%;
          padding: 50px 30px;
          background-color: $gold;
        }

        .about-heading {
          margin: 0;
          max-width: 366px;

          @media (max-width: 1023px) {
            max-width: 100%;
          }
        }
      }

      .content-container {
        position: relative;
        width: calc(55vw - ((100vw - 100%) / 2));
        padding-left: 100px;
        display: flex;
        flex-direction: column;

        @media (max-width: 1023px) {
          width: 100%;
          padding: 50px 30px;
        }

        &:before {
          content: '';
          position: absolute;
          top: -30px;
          left: 50px;
          width: 140px;
          height: 140px;
          border-radius: 140px;
          background-color: $beige;

          @media (max-width: 1023px) {
            top: 20px;
            left: 10px;
          }
        }

        .about-subtitle {
          position: relative;
          margin: 0 0 65px;
        }

        .about-text {
          @extend .p2;
        }
      }
    }
  }

  .numbers-line {
    width: 100%;
    display: flex;

    @media (max-width: 1240px) {
      flex-direction: column;
    }

    .left-side {
      width: 45vw;
      display: flex;

      @media (max-width: 1240px) {
        width: 100%;
        border-bottom: 1px solid $dark;
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .right-side {
      width: 55vw;
      display: flex;
      border-left: 1px solid $dark;

      @media (max-width: 1240px) {
        width: 100%;
        border-left: none;
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .number-container {
      flex: 1;
      padding: 60px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;

      @media (max-width: 1240px) {
        width: 55vw;
        flex: auto;
      }

      @media (max-width: 767px) {
        width: 100%;
        border-bottom: 1px solid $dark;

        &:last-child {
          border-bottom: none;
        }
      }

      @media (max-width: 520px) {
        justify-content: flex-start;
      }

      &:first-child {
        border-right: 1px solid $dark;

        @media (max-width: 1240px) {
          width: 45vw;
        }

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .number-value {
        font-family: $fontSimula;
        font-style: normal;
        font-weight: 400;
        font-size: 80px;
        line-height: 1.3em;
        color: $blue;
        margin: 0;
      }

      .number-text {
        @extend h3;
        margin: 0;
        max-width: 200px;
      }
    }
  }

  .about-bottom {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 110px 0 100px;
    background-color: $beige;

    @media (max-width: 1023px) {
      padding: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 45vw;
      background-color: $lightBrown;
      border-right: 1px solid $dark;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    .bottom-container {
      position: relative;
      @extend .width-container;
      align-items: center;

      @media (max-width: 1023px) {
        flex-direction: column;
        padding: 0;
      }

      .heading-container {
        width: calc(45vw - ((100vw - 100%) / 2));

        @media (max-width: 1023px) {
          width: 100%;
          padding: 50px 30px;
          background-color: $lightBrown;
        }

        .about-heading {
          margin: 0;
          max-width: 366px;

          @media (max-width: 1023px) {
            max-width: 100%;
          }
        }
      }

      .content-container {
        position: relative;
        width: calc(55vw - ((100vw - 100%) / 2));
        padding-left: 100px;
        display: flex;
        flex-direction: column;

        @media (max-width: 1023px) {
          width: 100%;
          padding: 50px 30px;
        }

        .about-subtitle {
          position: relative;
          margin: 0 0 65px;
        }

        .about-text {
          @extend .p2;
        }
      }
    }
  }
}
