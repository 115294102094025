.navigation-cta {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $lightBrown;
  padding: 50px 0;

  @media (max-width: 1023px) {
    padding: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 45vw;
    border-right: 1px solid $dark;
    background-color: $gold;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .navigation-container {
    position: relative;
    @extend .width-container;
    align-items: flex-end;

    @media (max-width: 1023px) {
      flex-direction: column;
      padding: 0;
    }

    .left-side {
      width: calc(45vw - ((100vw - 100%) / 2));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 50px;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 30px;
        background-color: $gold;
      }

      .navigation-discover {
        @extend .p1;
        margin: 0 0 15px;
      }

      .navigation-title {
        position: relative;
        @extend h1;
        margin: 0;

        @media (max-width: 767px) {
          width: 100%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 10px;
          right: -80px;
          width: 60px;
          height: 60px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMzAiIGZpbGw9IiNEOENBQTUiLz48cGF0aCBkPSJNMzkuMTg3IDMwLjk0OEgxNC43OVYyOS43MmgyNC4zOTdjLTMuMzktMS43NjktNS4zNTItNS40MzYtNS44NDQtMTAuOTM0bDEuMTkzLS4xMTljLjY2MyA3LjQxNyA0LjAzNiAxMS4wMiAxMC4yNTQgMTEuMDJ2MS4yOTRjLTYuMjE4IDAtOS41OSAzLjYwMy0xMC4yNTQgMTEuMDJsLTEuMTkzLS4xMmMuNDkyLTUuNDk4IDIuNDU0LTkuMTY1IDUuODQ0LTEwLjkzM1oiIGZpbGw9IiMxMzEzMTMiLz48L3N2Zz4=");
          background-size: contain;
          background-repeat: no-repeat;

          @media (max-width: 767px) {
            bottom: -80px;
            right: 50%;
            transform: translateX(50%);
          }
        }
      }
    }

    .right-side {
      width: calc(55vw - ((100vw - 100%) / 2));
      padding-left: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 30px;
      }

      .navigation-text {
        margin: 0;
      }
    }
  }
}
