.services04 {
  width: 100%;
  display: flex;
  flex-direction: column;

  .top-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: calc(45vw + 1px);
      border-right: 1px solid $dark;

      @media (max-width: 1023px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    .top-container {
      @extend .width-container;

      .content-container {
        padding-left: calc(45vw - ((100vw - 100%) / 2) + 100px);

        @media (max-width: 1023px) {
          padding-left: 40px;
        }

        .content-wrapper {
          position: relative;
          padding: 200px 0;

          @media (max-width: 1023px) {
            padding: 120px 0;
          }

          &:before {
            content: '';
            position: absolute;
            inset: 0;
            background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgMzIwIj48Y2lyY2xlIG9wYWNpdHk9Ii42IiBjeD0iMTYwIiBjeT0iMTYwIiByPSIxNTkuNSIgc3Ryb2tlPSIjMTMxMzEzIi8+PC9zdmc+");
            background-size: 80% auto;
            background-repeat: no-repeat;
            background-position: center left;
            opacity: 0.6;

            @media (max-width: 1023px) {
              background-size: auto calc(100% - 60px);
            }

            @media (max-width: 767px) {
              left: -50px;
              background-size: auto 270px;
            }
          }

          &:after {
            content: '';
            position: absolute;
            left: -60px;
            top: 50%;
            transform: translateY(-50%);
            width: 250px;
            height: 250px;
            border-radius: 250px;
            background-color: $lightBeige;

            @media (max-width: 1023px) {
              left: -40px;
              width: 175px;
              height: 175px;
              border-radius: 175px;
            }

            @media (max-width: 767px) {
              left: -65px;
              width: 125px;
              height: 125px;
              border-radius: 125px;
            }
          }

          .content-heading {
            position: relative;
            margin: 0;
            z-index: 2;
          }
        }
      }
    }
  }

  .heading-section {
    width: 100%;
    padding: 50px 0;
    background-color: $lightGold;
    display: flex;
    justify-content: center;

    @media (max-width: 1023px) {
      display: none;
    }

    .heading-container {
      @extend .width-container;
      justify-content: space-between;
      align-items: center;

      .side-container {
        width: calc((100% - 55px) / 2);
        display: flex;

        &:first-child {
          justify-content: flex-end;

          .heading {
            text-align: right;
          }
        }

        .heading {
          margin: 0;
        }
      }
    }
  }

  .text-section {
    width: 100%;
    padding: 50px 0;
    background-color: $beige;
    display: flex;
    justify-content: center;

    @media (max-width: 1023px) {
      padding: 0;
    }

    .text-container {
      @extend .width-container;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 1023px) {
        padding: 0;
        flex-direction: column;
      }

      .side-container {
        width: calc((100% - 55px) / 2);
        display: flex;
        flex-direction: column;

        @media (max-width: 1023px) {
          width: 100%;
        }

        &:first-child {
          justify-content: flex-end;

          .text, .heading {
            text-align: right;

            @media (max-width: 1023px) {
              text-align: left;
            }
          }
        }

        .heading {
          display: none;
          margin: 0;
          background-color: #F1EDE0;
          padding: 30px;

          @media (max-width: 1023px) {
            display: block;
          }
        }

        .text {
          @extend .p1;
          margin: 0;

          @media (max-width: 1023px) {
            padding: 30px 30px 50px;
          }
        }
      }
    }
  }
}
