.projects-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .projects-filters {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 45px 0 30px;

    @media (max-width: 767px) {
      padding: 20px 0;
    }

    .filters-container {
      @extend .width-container;
      align-items: center;
      gap: 50px;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      &.is-open .filter-select {
        display: block;
      }

      .filters-label {
        font-family: $fontSuisse;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3em;
        color: $dark;
        margin: 0;
        padding-left: 34px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEwIDE4aDR2LTJoLTR2MlpNMyA2djJoMThWNkgzWm0zIDdoMTJ2LTJINnYyWiIgZmlsbD0iI0Q4Q0FBNSIvPjwvc3ZnPg==");
        background-position: left top -4px;
        background-repeat: no-repeat;
        background-size: auto 24px;
        display: none;

        @media (max-width: 767px) {
          display: block;
        }
      }

      .filter-select {
        appearance: none;
        outline: none;
        background: transparent;
        border: none;
        font-family: $fontSuisse;
        font-style: normal;
        padding-right: 30px;
        width: 200px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3em;
        color: $dark;
        padding-right: 26px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxMSI+PHBhdGggZD0ibTEgMSA4IDggOC04IiBzdHJva2U9IiNEOENBQTUiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==");
        background-position: right top 3px;
        background-repeat: no-repeat;
        background-size: 16px auto;
        cursor: pointer;

        @media (max-width: 767px) {
          width: 100%;
          display: none;
        }
      }
    }
  }

  .projects-list {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 16px;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    .project-container {
      width: 100%;
      display: flex;
      width: calc((100% - 16px) / 2);

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    .project-item {
      position: relative;
      width: 100%;
      display: flex;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: $dark;
        opacity: 0.3;
        z-index: 2;
      }

      &:hover {
        .project-image-container .project-image {
          transform: scale(1.15);
        }
      }

      .project-image-container {
        width: 100%;
        display: flex;
        overflow: hidden;

        .project-image {
          width: 100%;
          transition: transform 0.8s ease-in-out;
        }
      }

      .project-type {
        position: absolute;
        z-index: 3;
        top: 60px;
        left: 60px;
        @extend .p3;
        color: $white;

        @media (max-width: 767px) {
          top: 30px;
          left: 30px;
        }
      }

      .project-info {
        position: absolute;
        z-index: 3;
        bottom: 40px;
        left: 60px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (max-width: 767px) {
          bottom: 20px;
          left: 30px;
          gap: 5px;
        }

        .project-name {
          margin: 0;
          color: $white;
        }

        .project-location {
          @extend .p1;
          color: $white;
          margin: 0;
        }
      }
    }

    .project-popup {
      position: fixed;
      inset: 0;
      z-index: 14;
      pointer-events: none;

      &.is-active {
        pointer-events: auto;
        z-index: 8;

        .popup-overlay {
          opacity: 1;
        }

        .popup-container {
          transform: translateX(0);
          opacity: 1;
        }
      }

      .popup-overlay {
        position: absolute;
        inset: 0;
        background-color: rgba($dark, 0.6);
        opacity: 0;
        transition: opacity .2s ease-in-out;
      }

      .popup-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: calc(100% - 30px);
        max-width: 810px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        transform: translateX(50%);
        opacity: 0;
        transition: opacity .4s ease-in-out, transform .4s ease-in-out;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .popup-close {
          position: absolute;
          top: 30px;
          right: 30px;
          padding: 20px;
          cursor: pointer;
          z-index: 14;

          svg {
            height: 30px;
          }
        }

        .image-container {
          width: 100%;
          height: 100%;
          display: flex;
          position: relative;

          @media (max-width: 767px) {
            flex-direction: column;
            height: auto;
          }

          .image-wrapper {
            position: absolute;
            inset: 0;

            @media (max-width: 767px) {
              position: relative;
              inset: initial;
            }

            .project-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .project-top-info {
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(45% + 1px);
            padding: 30px 20px 25px 40px;
            background-color: $lightGold;
            display: flex;
            flex-direction: column;
            gap: 5px;

            @media (max-width: 767px) {
              position: relative;
              bottom: initial;
              left: initial;
              width: 100%;
              padding: 20px;
            }

            .project-name {
              margin: 0;
            }

            .project-location {
              @extend .p2;
              margin: 0;
            }
          }
        }

        .info-container {
          flex-shrink: 1;
          width: 100%;
          display: flex;
          align-items: stretch;

          @media (max-width: 767px) {
            flex-direction: column;
            gap: 30px;
          }

          .left-side {
            width: 45%;
            border-right: 1px solid $dark;
            padding: 40px 40px 30px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 30px;

            @media (max-width: 767px) {
              width: 100%;
              padding: 20px;
            }
          }

          .right-side {
            width: calc(55% - 1px);
            padding: 40px 40px 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            @media (max-width: 767px) {
              width: 100%;
              padding: 0 20px 20px;
              gap: 30px;
              justify-content: flex-start;
            }
          }

          .info-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;

            .info-label {
              @extend .p5;
              text-transform: uppercase;
              font-weight: 600;
              margin: 0
            }

            .info-value {
              @extend .p3;
              color: $lightBlack;
            }
          }

          .project-link {
            @extend .inline-link;
          }
        }
      }
    }

    .projects-separator {
      width: calc((100% - 16px) / 2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 40px;
      gap: 50px;

      @media (max-width: 1023px) {
        width: 100%;
      }

      @media (max-width: 767px) {
        padding: 40px 30px;
      }

      .separator-heading {
        margin: 0;

        @media (max-width: 1200px) {
          font-size: 56px;
        }

        @media (max-width: 767px) {
          font-size: 44px;
          line-height: 1.15em;
        }
      }

      .separator-button {
        @extend .button;
      }
    }
  }
}
