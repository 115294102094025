.header {
  width: 100%;
  position: sticky;
  z-index: 8;
  top: 0;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: $white;

  @media (max-width: 767px) {
    padding: 10px 0;
  }

  .header-container {
    @extend .width-container;
    align-items: center;
    justify-content: space-between;

    .header-logo {
      display: flex;

      svg {
        height: 75px;

        @media (max-width: 767px) {
          height: 50px;
        }
      }
    }

    .header-right-site {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 60px;

      @media (max-width: 767px) {
        gap: 30px;
      }

      .header-button {
        @extend .button;

        @media (max-width: 424px) {
          padding: 10px 20px 8px;
          min-width: 100px;
        }

        span:first-child {
          display: inline;

          @media (max-width: 424px) {
            display: none;
          }
        }

        span:last-child {
          display: none;

          @media (max-width: 424px) {
            display: inline;
          }
        }
      }

      .mobile-menu-trigger {
        display: flex;
        cursor: pointer;

        svg {
          height: 13px;
        }
      }
    }
  }
}
