.members-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 15px;
  background-color: $beige;
  padding: 15px;

  @media (max-width: 767px) {
    padding: 30px 0;
    gap: 10px;
  }

  .member-container {
    width: calc((100% - 30px) / 3);

    @media (max-width: 1023px) {
      width: calc((100% - 15px) / 2);
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    .member-item {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .member-image {
        width: 100%;
        display: flex;

        img {
          width: 100%;

          &.desktop {
            @media (max-width: 520px) {
              display: none;
            }
          }

          &.mobile {
            display: none;

            @media (max-width: 520px) {
              display: block;
            }
          }
        }
      }

      .member-info {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $white;
        padding: 5px 30px;
        min-height: 145px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;

        @media (max-width: 1240px) {
          min-height: 160px;
        }

        @media (max-width: 767px) {
          padding: 30px;
          min-height: auto;
        }

        @media (max-width: 520px) {
          position: relative;
        }

        .member-name {
          margin: 0;
        }

        .member-position {
          @extend .p2;
          margin: 0;
          color: $lightBlack;
        }
      }
    }

    .member-popup {
      position: fixed;
      inset: 0;
      background-color: $white;
      z-index: 8;
      transition: all .7s ease-in-out;
      will-change: transform, opacity;
      display: flex;
      transform: translateX(20%);
      opacity: 0;
      pointer-events: none;

      @media (max-width: 767px) {
        transition-duration: .5s;
      }

      &.is-open {
        transform: translateX(0);
        opacity: 1;
        pointer-events: auto;
      }

      @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        background-color: $beige;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 45vw;
        background-color: $beige;
        border-right: 1px solid $dark;

        @media (max-width: 1023px) {
          width: 135px;
          background-color: transparent;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 120px;
        background-color: $lightGold;

        @media (max-width: 1240px) {
          display: none;
        }
      }

      .more-decoration {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 9;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 260px;
          border-bottom: 1px solid $dark;

          @media (max-width: 1023px) {
            height: 170px;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 110px;
          right: calc(55vw - 120px);
          width: 35vw;
          height: 35vw;
          border-radius: 35vw;
          border: 1px solid $dark;
          opacity: 0.6;

          @media (max-width: 1023px) {
            top: 90px;
            left: 10px;
            right: initial;
            width: 355px;
            height: 355px;
            border-radius: 355px;
          }
        }
      }

      .popup-close {
        position: absolute;
        padding: 30px;
        cursor: pointer;
        top: 20px;
        right: 20px;
        z-index: 9;

        @media (max-width: 1023px) {
          position: relative;
          background-color: $lightGold;
          padding: 20px 30px;
          display: flex;
          justify-content: flex-end;
          top: initial;
          right: initial;
        }

        svg {
          height: 30px;
        }
      }

      .left-side {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 45vw;
        z-index: 10;

        @media (max-width: 1023px) {
          position: relative;
          top: initial;
          bottom: initial;
          left: initial;
          width: 100%;
        }

        .content-container {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 180px 40px 0;

          @media (max-width: 1240px) {
            padding-top: 100px;
          }

          @media (max-width: 1023px) {
            padding: 50px 30px 30px;
            justify-content: flex-start;
          }

          .image-container {
            position: relative;
            width: 100%;
            max-width: 440px;
            display: flex;

            @media (max-width: 1023px) {
              max-width: 315px;
            }

            .member-image {
              width: 100%;
              display: flex;

              img {
                width: 100%;
              }
            }

            .member-info {
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              background-color: $white;
              padding: 30px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;

              .member-name {
                margin: 0;
              }

              .member-position {
                @extend .p2;
                margin: 0;
                color: $lightBlack;
              }
            }
          }
        }
      }

      .right-side {
        position: absolute;
        left: calc(45vw + 1px);
        right: 120px;
        top: 0;
        bottom: 0;
        z-index: 10;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @media (max-width: 1240px) {
          right: 0;
        }

        @media (max-width: 1023px) {
          position: relative;
          left: initial;
          right: initial;
          top: initial;
          bottom: initial;
          overflow-y: initial;
        }

        .content-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 190px 40px 40px 100px;

          @media (max-width: 1240px) {
            padding-top: 100px;
          }

          @media (max-width: 1023px) {
            padding: 40px 30px;
            background-color: $white;
          }

          .contact-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 70px;

            @media (max-width: 1023px) {
              margin-bottom: 30px;
            }

            .contact-text {
              @extend .p4;
              margin: 0;
            }
          }

          .main-content {
            width: 100%;
            display: flex;
            flex-direction: column;

            .member-heading {
              margin: 0 0 40px;

              @media (max-width: 1023px) {
                margin-bottom: 20px;
              }
            }

            .member-description {
              @extend .p2;
              color: $lightBlack;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
