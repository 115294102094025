// Titles
h1 {
  font-family: $fontSimula;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 1.2em;
  text-transform: uppercase;
  color: $dark;

  &.small {
    font-size: 56px;
  }

  @media (max-width: 1023px) {
    font-size: 56px;
  }

  @media (max-width: 767px) {
    font-size: 44px;
    line-height: 1.15em;
  }
}

h2 {
  font-family: $fontSimula;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 1.2em;
  color: $dark;

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 1.3em;
  }
}

h3 {
  font-family: $fontSimula;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.3em;
  color: $dark;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 1.4em;
  }
}

h4 {
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3em;
  color: $dark;
}

h5 {
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25em;
  color: $dark;
}

// Texts
.p1 {
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4em;
  color: $dark;
}

.p2 {
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.55em;
  color: $dark;
}

.p3 {
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5em;
  color: $dark;
}

.p4 {
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4em;
  color: $dark;
}

.p5 {
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5em;
  color: $dark;
}
