.home-projects {
  width: 100%;
  display: flex;
  flex-direction: column;

  .projects-slider {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $gold;

    .slider-container {
      position: relative;
      @extend .width-container;
      flex-direction: column;

      .flickity-viewport {
        width: 100%;
      }

      .flickity-prev-next-button {
        width: 90px;
        height: 90px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5MCA5MCI+PGNpcmNsZSBjeD0iNDUiIGN5PSI0NSIgcj0iNDUiIGZpbGw9IiNEOENBQTUiLz48cGF0aCBkPSJNNTkuNDQzIDQ2LjIxOUgyMi4xODV2LTEuODIyaDM3LjI1OGMtNS4xNzUtMi42MjQtOC4xNzMtOC4wNjQtOC45MjUtMTYuMjJMNTIuMzQgMjhDNTMuMzUzIDM5LjAwMyA1OC41MDQgNDQuMzQ3IDY4IDQ0LjM0N3YxLjkyMWMtOS40OTYgMC0xNC42NDcgNS4zNDQtMTUuNjYgMTYuMzQ3bC0xLjgyMi0uMTc2Yy43NTItOC4xNTYgMy43NS0xMy41OTcgOC45MjUtMTYuMjJaIiBmaWxsPSIjMTMxMzEzIi8+PC9zdmc+");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0;

        @media (max-width: 1023px) {
          width: 60px;
          height: 60px;
        }

        &.next {
          right: 0;

          @media (max-width: 1240px) {
            right: 20px;
          }
        }

        &.previous {
          left: 0;
          transform: translateY(-50%) rotate(180deg);

          @media (max-width: 1240px) {
            left: 20px;
          }
        }

        &:disabled {
          opacity: 0;
        }

        svg {
          display: none;
        }
      }

      .slider-progress-container {
        position: absolute;
        right: 90px;
        bottom: 40px;
        display: flex;
        flex-direction: column;
        z-index: 3;

        @media (max-width: 1023px) {
          bottom: initial;
          top: 40px;
        }

        @media (max-width: 767px) {
          top: 30px;
          right: 50px;
        }

        .progress-text {
          min-width: 75px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin: 0 0 12px;

          span {
            font-family: $fontSimula;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 1.3em;
            color: rgba($white, 0.6);

            &:first-child {
              color: $white;
              margin-right: 10px;
            }
          }
        }

        .progress-bar {
          width: 100%;
          background-color: rgba($white, 0.6);

          .progress-bar-inside {
            height: 1px;
            width: 0;
            background-color: $white;
            transition: width ease-in-out 0.2s;
          }
        }
      }

      .project-slide {
        position: relative;
        width: 100%;
        display: flex;

        .image-container {
          position: relative;
          width: 100%;
          display: flex;

          // &:after {
          //   content: '';
          //   position: absolute;
          //   inset: 0;
          //   background-color: rgba(#000000, 0.5);
          // }

          img {
            width: 100%;

            &.desktop {
              @media (max-width: 767px) {
                display: none;
              }
            }

            &.mobile {
              display: none;

              @media (max-width: 767px) {
                display: block;
              }
            }
          }
        }

        .top-info {
          position: absolute;
          top: 40px;
          left: 50px;
          display: flex;

          @media (max-width: 767px) {
            top: 30px;
            left: 20px;
          }

          .project-type {
            @extend .p3;
            margin: 0;
            color: $white;
          }
        }

        .bottom-info {
          position: absolute;
          bottom: 40px;
          left: 50px;
          right: 50px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (max-width: 767px) {
            bottom: 30px;
            left: 20px;
            right: 20px;
          }

          .project-location {
            @extend .p5;
            text-transform: uppercase;
            margin: 0 0 10px;
            color: $white;
          }

          .project-title {
            color: $white;
            margin: 0 0 30px;
          }

          .project-link {
            @extend .inline-link;
            @extend .light;
          }
        }
      }
    }
  }
}
