// --------------------------------------------------
// Import Fonts
// --------------------------------------------------

@font-face {
  font-family: 'Simula';
  src: url('/fonts/simula-book-webfont.woff2') format('woff2'),
  url('/fonts/simula-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisseintl-regular-webfont.woff2') format('woff2'),
  url('/fonts/suisseintl-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/fonts/suisseintl-semibold-webfont.woff2') format('woff2'),
  url('/fonts/suisseintl-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
