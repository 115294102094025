.services02 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $white;
  padding: 80px 0 50px;

  @media (max-width: 1023px) {
    padding: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 45vw;
    border-right: 1px solid $dark;
    background-color: $lightGold;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .services02-container {
    position: relative;
    @extend .width-container;
    align-items: center;

    @media (max-width: 1023px) {
      flex-direction: column;
      padding: 0;
    }

    .left-side {
      width: calc(45vw - ((100vw - 100%) / 2));
      padding-right: 60px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 30px;
        background-color: $lightGold;
      }

      .services02-text {
        margin: 0;
      }
    }

    .right-side {
      width: calc(55vw - ((100vw - 100%) / 2));
      padding-left: 100px;

      .services02-heading {
        margin: 0;
      }

      @media (max-width: 1023px) {
        width: 100%;
        padding: 50px 30px;
      }
    }
  }
}
