.button {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-out 0.2s;
  cursor: pointer;
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  padding: 14px 40px 12px;
  min-width: 142px;
  border: 1px solid $lightBeige;
  color: $dark;
  background-color: transparent;

  &:hover {
    border-color: $lightGold;
    background-color: $lightGold;
  }

  &:disabled, &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.inline-link {
  position: relative;
  display: flex;
  align-items: center;
  font-family: $fontSuisse;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25em;
  color: $dark;
  margin: 0;

  &:hover {
    &:before {
      width: calc(100% - 35px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 1px;
    background-color: $dark;
    transition: width ease-in-out 0.2s;
  }

  &:after {
    content: '';
    width: 25px;
    height: 19px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAxOSI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOS4wNDMgOC43MTRIMHYxLjU3MmgxOS4wNDNjLTIuMjg3IDEuNjAyLTMuNTk4IDQuMzk1LTMuOTU2IDguMjYybC0uMDI4LjMgMS41NzQuMTUyLjAyOC0uMzAzYy4yNjYtMi44NzcgMS4wNzItNC45NjUgMi4zOTEtNi4zMzMgMS4zMTMtMS4zNjIgMy4xNzUtMi4wNTIgNS42NDUtMi4wNTJIMjVWOC42ODhoLS4zMDNjLTIuNDcgMC00LjMzMi0uNjktNS42NDUtMi4wNTItMS4zMTktMS4zNjgtMi4xMjUtMy40NTYtMi4zOTEtNi4zMzNMMTYuNjMzIDBsLTEuNTc0LjE1MS4wMjguMzAxYy4zNTggMy44NjcgMS42NjkgNi42NiAzLjk1NiA4LjI2MloiIGZpbGw9IiNEOENBQTUiLz48L3N2Zz4=");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 10px;
    margin-top: -3px;
  }

  &.light {
    color: $white;

    &:before {
      background-color: $white;
    }
  }
}
