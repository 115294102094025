.home-team {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $lightGold;
  padding: 50px 0;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 45vw;
    background-color: $beige;
    border-right: 1px solid $dark;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .team-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(55vw - 180px);
    display: flex;

    @media (max-width: 1023px) {
      width: calc(55vw - 1px);
    }

    @media (max-width: 767px) {
      position: relative;
      width: 100%;
      padding-bottom: 70px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .team-container {
    position: relative;
    @extend .width-container;

    @media (max-width: 767px) {
      background-color: $beige;
      padding: 50px 30px;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(45vw - ((100vw - 100%) / 2));
      padding-right: 100px;

      @media (max-width: 1023px) {
        padding-right: 50px;
      }

      @media (max-width: 767px) {
        width: 100%;
        padding: 0;
      }

      .content-heading {
        margin: 0 0 20px;
      }

      .content-text {
        @extend .p2;
        margin: 0 0 30px;
      }

      .member-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

        .member-name {
          font-family: $fontSuisse;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 1.5em;
          color: $dark;
          margin: 0;
        }

        .member-position {
          font-family: $fontSuisse;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5em;
          color: rgba($dark, .8);
          margin: 0;
        }
      }

      .content-link {
        @extend .inline-link;
      }
    }
  }
}
