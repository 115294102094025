.page-heading {
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: $blue;
  min-height: 360px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 45vw;
    background-color: $lightBlue;
    border-right: 1px solid $dark;
    pointer-events: none;

    @media (max-width: 1023px) {
      background-color: transparent;
      z-index: 2;
    }

    @media (max-width: 767px) {
      width: 36vw;
      border-color: rgba($dark, 0.4);
    }
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url('/img/site/header-circle.svg');
    background-size: auto calc(100% - 40px);
    background-repeat: no-repeat;
    background-position: right calc(55vw - 80px) center;
    pointer-events: none;
    opacity: 0.6;

    @media (max-width: 1023px) {
      background-position: center center;
      opacity: 0.4;
    }

    @media (max-width: 520px) {
      background-position: top 70px center;
      background-size: auto calc(100vw - 40px);
    }
  }

  .heading-container {
    position: relative;
    @extend .width-container;
    align-items: stretch;

    @media (max-width: 1023px) {
      flex-direction: column;
      padding: 0;
    }

    .left-side {
      position: relative;
      width: calc(45vw - ((100vw - 100%) / 2));
      padding: 40px 0;
      display: flex;
      align-items: center;
      z-index: 1;

      @media (max-width: 1023px) {
        width: 100%;
        padding: 40px 30px;
        background-color: $lightBlue;
      }

      .heading {
        margin: 0;

        &.is-large {
          width: min(100vw - 80px, 850px);
        }
      }
    }

    .right-side {
      position: relative;
      width: calc(55vw - ((100vw - 100%) / 2));
      padding: 40px 0 40px 200px;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100px;
        background-color: rgba($white, 0.2);

        @media (max-width: 1023px) {
          bottom: initial;
          width: 100%;
          height: 50px;
        }
      }

      @media (max-width: 1023px) {
        width: 100%;
        padding: 90px 30px 40px;
      }

      .subtitle {
        margin: 0;

        &.is-mobile-large {
          display: none;

          @media (max-width: 1023px) {
            display: flex;
            color: var(--primary-black, #131313);
            font-family: $fontSimula;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3;
          }
        }
      }
    }
  }
}
